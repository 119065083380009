<template>
  <div>
      <machine-learning-query></machine-learning-query>
  </div>
</template>

<script>
import {
  default as MachineLearningQuery
} from '@/components/widgets/MachineLearningQuery';
import { mapGetters } from 'vuex';
export default {
  name: 'user_machine_learning_query_products',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    MachineLearningQuery,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
  },
  watch: {}
}

</script>

